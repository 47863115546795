body {
  background: #ffffff;
}

/* Font families references */

@font-face {
  font-family: "Roboto";
  src: url(./fonts/Roboto-Regular.woff);
  font-weight: normal;
}

@font-face {
  font-family: "Geogrotesque";
  src: url(./fonts/Geogrotesque-Regular.woff);
  font-weight: normal;
}

/* Landing Page */
.landingpage-vertical {
  margin-bottom: 20px;
}

.App-link {
  color: #61dafb;
}

.overall-background {
  /* background: linear-gradient(138.28deg, rgba(255, 255, 255, 0.45) 3.45%, rgba(255, 255, 255, 0.2) 98.06%);
  backdrop-filter: blur(50px);
  border-radius: 12px 12px 0px 0px; */
}

/* Navbar */
.footer {
  position: fixed;
  bottom: 0;
  height: 70px;
  width: 100%;
}

.footer ul {
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-wrap: nowrap;
  height: inherit;
  margin: 0px;
}

.footer ul li {
  flex: 1;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #edf1f7;
}

.footer ul a {
  flex: 1;
  text-align: center;
  text-decoration: none;
}

.icon-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  width: 64px;
  height: 32px;
  /* blue/blue 700 CTA
  */
  background: #154898;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.navbar-segment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 64px;
  height: 32px;

  border-radius: 16px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.navbar-icon {
  width: 24px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.active-navbar-item {
  position: "absolute";
  left: "3.85%";
  right: "3.92%";
  top: "3.85%";
  bottom: "3.85%";
  background: "#FFFFFF";
}

.common-navbar-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px 16px;
  gap: 4px;
}

.inactive-navbar-item {
  position: "absolute";
  left: "3.85%";
  right: "3.92%";
  top: "3.85%";
  bottom: "3.85%";
  background: #154898;
}

.common-navbar-icon-text {
  width: 80px;
  height: 18px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

.active-navbar-icon-text {
  color: #154898;
}

.inactive-navbar-icon-text {
  color: #484c4e;
}

/* sidebar */
.sidebar {
  height: 100vh;
  border-right: solid 1px #1a1c1c;
  /* how is this calculated? - why 110px*/
  width: 200px;
  position: fixed;
  overflow-x: hidden;
  padding-top: 10px;
  z-index: 1;
  top: 0;
  left: 0;
  list-style-type: none;
  margin-inline-start: 40px;
  margin-block-start: 0px;
  padding-inline-start: 10px;
  padding-inline-end: 0px;
}

.sidebar > li:first-child {
  padding: 40px 8px 50px 8px;
}

.sidebar li {
  /* padding: 8px 8px 20px 16px; */
  height: 48px;
  display: flex;
  align-items: center;
}

.sidebar a {
  text-decoration: none;
  vertical-align: middle;
}

.sidebar-item {
  font-family: Geogrotesque;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.active-sidebar-item {
  border-right: solid 2px #154898;
  color: #154898;
}

.inactive-sidebar-item {
  color: #484c4e;
}

/* Home Page */

.home-status {
  display: flex;
  column-gap: 20px;
  align-content: center;
}

.battery-status {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.status-common {
  height: 50vh;
  /* flex: 1; */
  /* border: 2px solid red; */
  display: flex;
  column-gap: 40px;
}

@media only screen and (max-width: 600px) {
  .status-common {
    display: flex;
    column-gap: 20px;
    align-content: center;
  }
}

.device-status {
  width: 50%;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
}

/* Battery status */
.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle {
  stroke: #0c80a1;
  fill: transparent;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

svg text {
  text-anchor: middle;
  dominant-baseline: middle;
}

.landing-desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.05em;
  text-align: center;
}

.baxi-button-wrapper {
  height: 80px;
  align-items: center;
}

button.baxi-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  min-width: 200px;
  height: 48px;
  background: #154898;
  border-radius: 24px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  box-shadow: 1px 4px 5px #908f8f;
}

button.baxi-primary-button {
  font-size: 16px;
}

button.baxi-outlined-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  min-width: 200px;
  height: 48px;
  background: #fff;
  border-radius: 24px;
  color: #154898;
  font-weight: 600;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  border: none;
}

button.baxi-hollow-button {
  background: white;
  color: #154898;
  box-shadow: none;
  border: 1px solid #154898;
  font-size: 16px;
}


button.baxi-button:hover {
  background: #0e3167;
}

button.baxi-button:disabled {
  background: rgba(26, 28, 28, 0.49);
  color: #fff;
}

.text-info {
  min-width: 250px;
  text-align: center;
  letter-spacing: 0.05em;
  color: black;
  font-weight: 500;
  margin-top: 10px;
  font-size: 16px;
  box-shadow: none;
}

.text-error {
  color: #d23938;
  letter-spacing: 0.05em;
  font-weight: bolder;
  margin-top: 10px;
  font-size: 16px;
  box-shadow: none;
  text-align: center;
}

.patient-symptoms-main > .patient-symptoms-container {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  align-items: center;
}

.patient-symptoms-main button {
  padding: 8px 12px;
  width: 80%;
  margin-bottom: 11px;
  font-weight: 600;
  border: 1px solid #154898;
  color: #154898;
  border-radius: 24px;
  font-family: "Geogrotesque";
  font-style: normal;
  font-size: 16px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.patient-symptoms-main button.selected {
  background: #edf1f7;
}

.patient-symptoms-history {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
  width: 100vw;
}

.patient-symptoms-history > * .history-date {
  font-size: 20px;
  font-family: Geogrotesque;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.patient-symptoms-history > * .history-time {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  min-width: 100px;
  color: #1a1c1c;
}

.patient-symptoms-history > * .history-symptoms-desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1a1c1c;
}

.page-header {
  font-family: "Geogrotesque";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  padding: 24px;
  color: #1a1c1c;
  background-color: #fff;
}

.sticky-header {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1000
}

.page-secondary-header {
  font-family: "Geogrotesque";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  padding: 24px;
  color: #1a1c1c;
  background-color: #fff;
}

.app-version {
  font-family: "Geogrotesque";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  padding: 24px;
  color: #9c9c9c;
}

.message-header {
  text-transform: capitalize;
}

.message-header.success {
  color: black;
}

.message-header.failure {
  color: #d23938;
}

.home-welcome {
  font-family: "Geogrotesque";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: #1a1c1c;
  padding: 5px 20px 20px 20px;
}

.home-welcome-desc {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

.home-welcome-desc .desc {
  margin-bottom: 16px;
 }

.home-welcome-desc .desc:last-child {
  margin-bottom: 0px;
 }


.video-instruction-container {
  animation: animateBg 1s infinite linear;
  background-color: #fff;
  background-image: linear-gradient(90deg, #dadada, #eeeeee, #dadada, #eeeeee);
  background-size: 300% 100%;
}

@keyframes animateBg  {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.faq-answer {
  padding: 16px;
  margin: -16px 0 0 -16px;
  border-left: 3px solid #154898;
}

.ptr__children {
  height: calc(100vh - 90px) !important;
}

.lds-ellipsis div {
  background: #154898 !important;
}

.ptr__pull-down--pull-more {
  opacity: 0;
}

.rec-symp-step-counter {
  font-family: Geogrotesque;
  font-size: 24px;
  color: white;
  background: #313334;
  padding: 5px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  text-align: center;
  font-weight: bold;
}

.rec-symp-step-title {
  font-family: Geogrotesque;
  font-size: 26px;
  color: #313334;
  font-weight: bold;
  margin-left: 18px;
  padding-top: 5px;
}

.rotate-element {
  transform-origin: center;
  animation: rotate 3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}